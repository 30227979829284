import config from '~/config'
import { parseToHsl, hslToColorString } from 'polished'

interface ITypographyConfig {
  baseFontSize?: number
  baseLineHeight?: number
  scaleRatio?: number
  multiplicity?: number
  roundToNearestHalfLine?: boolean
  minLinePadding?: number
  outputInRem?: boolean
}

function roundToMultiplicity(num: number, mul: number): number {
  return num + (num % mul)
}

function modularScale(steps: number, base: number, ratio: number) {
  return base * ratio ** steps
}

function floatToMinimalSting(f: number, fractionDigits: number = 4): string {
  const s1 = f.toString()
  const s2 = f.toFixed(fractionDigits)
  return s1.length < s2.length ? s1 : s2
}

const genericFontFamilies = [
  'inherit',
  'default',
  'serif',
  'sans-serif',
  'monospace',
  'fantasy',
  'cursive',
  '-apple-system',
]

function wrapFontFamily(fontFamily: string) {
  return genericFontFamilies.indexOf(fontFamily) !== -1
    ? fontFamily
    : `'${fontFamily}'`
}

class TypographyScale {
  readonly baseFontSize: number = 16
  readonly baseLineHeight: number = 1.5
  readonly scaleRatio: number = 2
  readonly multiplicity: number = 4
  readonly roundToNearestHalfLine: boolean = true
  readonly minLinePadding: number = 2

  constructor(cfg: ITypographyConfig = {}) {
    this.baseFontSize = cfg.baseFontSize || this.baseFontSize
    this.baseLineHeight = cfg.baseLineHeight || this.baseFontSize
    this.scaleRatio = cfg.scaleRatio || this.scaleRatio
    this.multiplicity = cfg.multiplicity || this.multiplicity
    this.roundToNearestHalfLine = cfg.roundToNearestHalfLine || this.roundToNearestHalfLine
    this.minLinePadding = cfg.minLinePadding || this.minLinePadding
  }

  public get lineHeightInPix() {
    return roundToMultiplicity(this.baseFontSize * this.baseLineHeight, this.multiplicity)
  }

  public get lineHeightInRem() {
    return this.lineHeightInPix / this.baseFontSize
  }

  public rem(sizeInPix: number): number {
    return sizeInPix / this.baseFontSize
  }

  public getLinesHeightInPix(lines: number): number {
    if (!lines) lines = 1;
    return lines * this.lineHeightInPix
  }

  public getLinesHeightInRem(lines: number): number {
    return this.rem(this.getLinesHeightInPix(lines))
  }

  public scaleInPix(value: number): number {
    const fontSize = modularScale(value, this.baseFontSize, this.scaleRatio)
    return fontSize
  }

  public scaleInRem(value: number): number {
    return this.rem(this.scaleInPix(value))
  }

  public lineHeightForFontPix(fontSizeInPix: number): number {
    const lines = this._linesForFontSize(fontSizeInPix, this.lineHeightInPix)
    return this.getLinesHeightInPix(lines)
  }

  public lineHeightForFontRem(fontSizeInRem: number): number {
    const lines = this._linesForFontSize(fontSizeInRem * this.baseFontSize, this.lineHeightInPix)
    return this.getLinesHeightInRem(lines)
  }

  protected _linesForFontSize(fontSizeInPix: number, lineHeightInPix: number) {
    const { roundToNearestHalfLine, minLinePadding } = this
    let lines = roundToNearestHalfLine
      ? Math.ceil((2 * fontSizeInPix) / lineHeightInPix) / 2
      : Math.ceil(fontSizeInPix / lineHeightInPix)

    if (lines * lineHeightInPix - fontSizeInPix < minLinePadding * 2) {
      lines += roundToNearestHalfLine ? 0.5 : 1
    }

    return lines
  }
}

const cfg = config.style
const ts = new TypographyScale(cfg)

const primaryHsl = parseToHsl(cfg.primaryColor)
const secondaryHsl = parseToHsl(cfg.secondaryColor)
const bodyHsl = parseToHsl(cfg.bodyColor)
const headerHsl = parseToHsl(cfg.headerColor)
const linkHsl = parseToHsl(cfg.linkColor)
const visitedHsl = parseToHsl(cfg.visitedColor)
const activeHsl = parseToHsl(cfg.activeColor)
const hoverHsl = parseToHsl(cfg.hoverColor)

export const primaryColor = hslToColorString(primaryHsl)
export const secondaryColor = hslToColorString(secondaryHsl)
export const headerColor = hslToColorString(headerHsl)
export const bodyColor = hslToColorString(bodyHsl)
export const linkColor = hslToColorString(linkHsl)
export const visitedColor = hslToColorString(visitedHsl)
export const activeColor = hslToColorString(activeHsl)
export const hoverColor = hslToColorString(hoverHsl)

export function rem(n: number) {
  return floatToMinimalSting(n) + 'rem'
}

export function pix(n: number) {
  return n + 'px'
}

export const headerFontFamilyString = cfg.headerFontFamily.map(wrapFontFamily).join(',')
export const bodyFontFamilyString = cfg.bodyFontFamily.map(wrapFontFamily).join(',')

export const pix2rem = ts.rem.bind(ts)
export const getLinesHeightInRem = ts.getLinesHeightInRem.bind(ts)
export const getLinesHeightInPix = ts.getLinesHeightInPix.bind(ts)
export const scaleInRem = ts.scaleInRem.bind(ts)
export const scaleInPix = ts.scaleInPix.bind(ts)
export const lineHeightForFontRem = ts.lineHeightForFontRem.bind(ts)
export const lineHeightForFontPix = ts.lineHeightForFontPix.bind(ts)

export const linesRem = (n: number) => rem(getLinesHeightInRem(n))
export const linesPix = (n: number) => pix(getLinesHeightInPix(n))

export const baseFontSize = ts.baseFontSize

export const baseLineHeight = ts.lineHeightInPix / baseFontSize
export const lineHeightInRem = ts.lineHeightInRem
export const lineHeightInPix = ts.lineHeightInPix

export const headerWeight = cfg.headerWeight.toString()
export const bodyWeight = cfg.bodyWeight.toString()
export const boldWeight = cfg.boldWeight.toString()

export const contentWidth = cfg.contentWidth
export const height16x9 = contentWidth / 16 * 9
export const height4x3 = contentWidth / 4 * 3
export const appBarHeight = cfg.appBarHeight
