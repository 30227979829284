import React, { useEffect, useState, createRef } from 'react'
// import styled from 'styled-components'
import Ratio from '~/components/Ratio'

interface IYoutubeView {
  link: string
}

const YoutubeView: React.FC<IYoutubeView> = ({ link }) => {
  const [showVideo, setShowVideo] = useState(false)
  const container = createRef<HTMLDivElement>()

  // const t = link.match(/\?t=(\d+)$/)[1]
  const id = link.match(/\/([^/]+?)$/)[1]

  useEffect(() => {
    if (
      window &&
      'IntersectionObserver' in window &&
      container &&
      container.current
    ) {
      const videoObserver = new IntersectionObserver(onVideoIntersection, {
        rootMargin: '100px 0px',
        threshold: 0.25,
      })
      function onVideoIntersection(entries: any) {
        if (!entries || entries.length <= 0) return
        if (entries[0].isIntersecting) {
          setShowVideo(true)
          videoObserver.disconnect()
        }
      }
      videoObserver.observe(container.current)
      return () => {
        videoObserver.disconnect()
      }
    } else {
      setShowVideo(true)
    }
  }, [container])

  function onIframeLoaded() {
    if (!window) return
    function loadPlayer() {
      const player = new window.YT.Player(id)
      window._player[id] = player
    }
    if (!window.YT) {
      window._player = {}
      var tag = document.createElement('script')
      tag.src = 'https://www.youtube.com/iframe_api'
      var firstScriptTag = document.getElementsByTagName('script')[0]
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
      window.onYouTubeIframeAPIReady = () => {
        loadPlayer()
        window.onYouTubeIframeAPIReady = null
      }
    } else {
      loadPlayer()
    }
  }

  return (
    <div className="mb-1" ref={container}>
      <Ratio style={{ background: 'black' }}>
        {showVideo ? (
          <iframe
            src={`https://www.youtube.com/embed/${id}?rel=0&enablejsapi=1`}
            //@ts-ignore
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            id={id}
            className="youtube-view"
            onLoad={onIframeLoaded}
          ></iframe>
        ) : undefined}
      </Ratio>
    </div>
  )
}

export default YoutubeView
