import React, { useEffect } from 'react'
import AppProvider from '~/providers/AppProvider'
import { MDXProvider } from '@mdx-js/react'
import YoutubeView from '~/components/media-views/YoutubeView'

const components = {
  youtube: YoutubeView,
}

const AppRoot: React.FC<{ element: React.Component }> = ({ element }) => {
  return (
    <AppProvider>
      <MDXProvider components={components}>{element}</MDXProvider>
    </AppProvider>
  )
}

export default AppRoot
