import React from 'react'
import styled from 'styled-components'
import { contentWidth, height16x9 } from '~/utils/styling'

const Wrapper = styled.div`
  position: relative;
  max-width: ${contentWidth}px;
  max-height: ${height16x9}px;
  &::before {
    content: '';
    display: block;
    padding-bottom: 56.25%;
  }

  & > :first-child {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
`

const Ratio: React.FC = ({
  children,
  ...props
}) => <Wrapper {...props}>{children}</Wrapper>

export default Ratio